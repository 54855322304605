import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Box, Container, Typography, useMediaQuery } from "@mui/material";
import { Button } from "./components/ButtonElements";
import {
  ArrowForward,
  ArrowRight,
} from "../src/components/HeroSection/HeroElements";
import { caseStudiesData } from "./data/caseStudiesData";
import CaseStudyCard from "./components/CaseStudyCard";
import { CaseStudy } from "./components/CaseStudy";
import { useTheme } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const IntegralPhiliosophy = () => {
  const { id } = useParams();
  const currentId = parseInt(id);
//   const currentCaseStudy = caseStudiesData.find(
//     (study) => study.id === currentId
//   );

  const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const filteredCaseStudiesData = caseStudiesData.filter(
    (study) => study.id !== currentId
  );

  // eslint-disable-next-line
  const [hover, setHover] = useState(false);

  // const onHover = () => {
  //   setHover(!hover);
  // };
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const settings = {
    infinite: true,
    dots: true,
    dotsClass: "slick-dots",
    speed: 700,
    slidesToShow: isSmallScreen ? 1 : 3, // Show 1 slide on small screens, 3 on larger ones
    rows: 1,
    slidesToScroll: isSmallScreen ? 1 : 3,
    vertical: false,
    verticalSwiping: true,
  };

  const data_philio =   {
    id: 9,
    title: "The Integral Advantage",
    // subheader: "We'd love to partner with you on your data journey, assisting you in navigating complex technical and business landscapes.",
    content: `## Our Value Proposition

The guides on your data journey helping you navigate complex technical and business landscapes. 
  
## Bridging the Gap Between Data and Business 

We pride ourselves on being bilingual in the languages of data and business. We bridge the gap between complex technical concepts and the needs of non-technical stakeholders, ensuring that the insights we provide are not only correct but also understandable and actionable. In essence, we are not just your data engineers/analysts, but your translators and strategists, translating your data into a language that your entire business can understand and act on. 
  
## Full Life-Cycle Data Consultants 

In the realm of data consulting, technical skills such as SQL querying or creating basic BI dashboards are commonplace. However, we believe that true data mastery involves a much deeper level of involvement and understanding throughout the entire data life-cycle. 
  
We are not just consultants who can build the technical side of things—we are full life-cycle consultants. This means we immerse ourselves completely in every facet of your data journey. We start from the initial stages of data collection, progressing to analysis and interpretation, and continue through to the application of insights derived from that data. 
  
By truly understanding the essence of your data and its impact on your business, we provide a more comprehensive and tailored service. This combination of technical ability and business acumen ensures that you receive not just data analysis, but meaningful insights that have a tangible positive impact on your business. We're not just working with your data, we're working with you, ensuring that the data serves your business in the most impactful way possible. 
  
## Offering an External Perspective 

We recognize that providing an external viewpoint holds value only when taking into account the internal intricacies of the problem.  Using our full life-cycle approach gives us the visibility into these internal intricacies so that we can offer a fresh, external perspective to solving the problem.   Free from the constraints of adhering to past approaches or internal politics, we bring an unbiased and innovative approach to tackling your challenges. 
  
## Experience 

Our team has experience developing solutions for a wide variety of industries including: Health Insurance, Wealth Management, the Department of Defense, Banking, and even Fantasy Sports.  We embrace the challenge of learning the inner workings of a new industry.   
  
## Problem Solvers 

At our core, we are a team driven by a passion for unraveling complex problems.  Our clients often give us their most challenging data problems to tackle.  We don't just recommend solutions to these problems, we oftentimes develop the algorithms and code to actually solve the problem.  To quote a message we received from one of our clients after tackling a particularly challenging problem: "Wow you can solve anything!!" 
  
# The Integral Approach 

## Flexibility 

With us, you gain flexible access to experts who strive to understand your data as deeply as you do. This is not a one-size-fits-all service but an agile, responsive team that adapts to your needs. Our team provides you with the ability to quickly scale up when you need deep dives into your data and scale down during periods of maintenance or slower growth. 
  
## Integral's Different Approach to Reporting 

Our approach is fundamentally different than what you typically find in industry. We view reporting as a means to an end - that end being insightful, actionable data that helps you make informed business decisions. We're adept at understanding the underlying questions you're trying to answer, and we work to find the most effective way to provide you with that insight. Sometimes, what initially seems to be the solution may not be the best way to address your needs. We bring a fresh, external perspective and can often find more efficient or effective ways to answer your questions. 
  
## Flexible Data Presentation 

Another key differentiation is our flexible approach to data presentation. Not every piece of analysis needs to become a permanent, regularly maintained report. We're experts at selecting the right medium for each piece of information. Perhaps a simple email with summary statistics can convey the insights you need. Or maybe a snippet of code can be created & stored on GitHub for ad-hoc execution as needed. And when it is necessary, we can design and implement automated reports that are refreshed regularly. In every case, our goal is to provide you with the information you need in the most suitable, efficient, and easily understood format. 
`,
  }

  return (
    <Container sx={{ py: "40px" }}>
      {data_philio && <CaseStudy {...data_philio} />}
      <Typography variant="h4" fontWeight={600} mt={12} mb={2}>
        Explore Our Case Studies
      </Typography>

      <Box
        sx={{
          maxWidth: "100%",
          margin: "auto",
          alignItems:'center'
        }}
      >
        <Slider {...settings}>
          {filteredCaseStudiesData.map((caseStudy) => (
            <Grid container md={12} p={2} mb={4}>
              <Grid item md={12} key={caseStudy.id}>
                <CaseStudyCard study={caseStudy} />
              </Grid>
            </Grid>
          ))}
        </Slider>

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
        <Button
          to="/case_studies"
          primary="true"
          dark="true"
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-80}
        >
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            View All Case Studies
          </Typography>
          {hover ? <ArrowForward /> : <ArrowRight />}
        </Button>
      </Box>
      </Box>

    </Container>
  );
};

export default IntegralPhiliosophy;
